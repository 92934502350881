@tailwind base;
@tailwind components;
@tailwind utilities;

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-picker-header-super-next-btn, .ant-picker-header-super-prev-btn{
  display:none
}

.ant-picker-cell > .date.disabled{
  background: #ffbdbd;
}

.ant-picker-cell > .date.disabled-hour{
  background: #ffe0a7;
}


.ant-picker-cell > .date {
  padding: 4px 0px;
}

tr.cancelled {
  background-color: #ec8282;
}

tr.cancelled > td {
  text-decoration: line-through;
  background-color: #ec8282;
}

tr.cancelled:hover {
  background-color: #ec8282;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
.ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    background: unset; 
}